<template>
  <b-container class="content-matches mb-5 ml-3">
    <b-row
      class="mt-3 mb-2 ml-1"
      align-v="end"
    >
      <b-col
        lg="8"
        class="d-inline-flex filters-row pr-0"
        style="border-bottom: 1px solid #e4e9f9"
      >
        <b-nav>
          <b-nav-item
            link-classes="py-2 py-sm-2 px-2 px-sm-3 filters"
            :class="{ active: statusFilter === 'open' }"
            :disabled="!openMatches"
            @click="setFilter('open')"
          >
            Open({{ openMatches }})
          </b-nav-item>
        </b-nav>
        <b-nav>
          <b-nav-item
            link-classes="py-2 py-sm-2 px-2 px-sm-3 filters"
            :disabled="!activeMatches"
            :class="{ active: statusFilter === 'active' }"
            @click="setFilter('active')"
          >
            Active({{ activeMatches }})
          </b-nav-item>
        </b-nav>
        <b-nav>
          <b-nav-item
            link-classes="py-2 py-sm-2 px-2 px-sm-3 filters"
            :disabled="!closedMatches"
            :class="{ active: statusFilter === 'closed' }"
            @click="setFilter('closed')"
          >
            Closed({{ closedMatches }})
          </b-nav-item>
        </b-nav>
        <b-nav>
          <b-nav-item
            link-classes="py-2 py-sm-2 px-2 px-sm-3 filters"
            :class="{ active: statusFilter === 'all' }"
            @click="setFilter('all')"
          >
            All({{ matches.length }})
          </b-nav-item>
        </b-nav>
      </b-col>
      <!-- Temp remove that. Might need to add it back later on :) -->
      <!-- <b-col class="filter-bar text-right pr-3">
        <b-form-group
          label-for="project-filter"
          label-class="mb-0"
          label-size="lg"
        >
          <b-form-select
            v-model="selected"
            class="project-filter"
            variant="beige"
            :options="testOptions"
          >
            <template #first>
              <b-form-select-option
                :value="null"
                disabled
              >
                Filter by project
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
      </b-col> -->
    </b-row>
    <b-row
      v-for="match in filteredMatches"
      :key="match.id"
    >
      <b-col>
        <MatchCard :match="match" />
      </b-col>
    </b-row>
    <b-row no-gutters>
      <b-col
        v-if="!(filteredMatches || []).length"
        class="p-0"
      >
        <NoMatchesNew />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import NoMatchesNew from '@/modules/recruiter/components/NoMatchesNew'
import MatchCard from '@/modules/recruiter/components/MatchCard'
import axios from '@/helpers/axios'
import { mapGetters } from 'vuex'
import { MATCH_STATUSES, statusByValue } from '@/helpers/statuses'

const STATUS_OPEN = [
  MATCH_STATUSES.new.value,
  MATCH_STATUSES.recruiterIsInterested.value,
  MATCH_STATUSES.clientIsInterested.value,
  MATCH_STATUSES.introMeeting.value,
  MATCH_STATUSES.proposal.value,
  MATCH_STATUSES.contractSigning.value,
  MATCH_STATUSES.recruiterDeclined.value,
  MATCH_STATUSES.notHired.value,
]

const STATUS_ACTIVE = [
  MATCH_STATUSES.feedback.value,
]

const STATUS_FINISHED = [
  MATCH_STATUSES.contractCompleted.value,
  MATCH_STATUSES.contractCanceled.value,
  MATCH_STATUSES.paused.value,
]

export default {
  name: 'Matches',
  components: {
    MatchCard,
    NoMatchesNew,
  },
  data () {
    return {
      matches: [],
      statusFilter: 'all',
      counters: {
        open: 0,
        active: 0,
        closed: 0,
        drafts: 0,
        all: 0,
      },
      selected: null,
      testOptions: [
        'Vue Frontend Developer',
        'Ux Designer',
        'Pipeline Building and management for Software Engineers',
      ],
    }
  },
  computed: {
    ...mapGetters('auth', ['agency']),
    openMatches () {
      return this.matches.filter((m) => STATUS_OPEN.includes(m.status)).length
    },
    activeMatches () {
      return this.matches.filter((m) => STATUS_ACTIVE.includes(m.status)).length
    },
    closedMatches () {
      return this.matches.filter((m) => STATUS_FINISHED.includes(m.status)).length
    },
    filteredMatches () {
      if (this.statusFilter === 'open') {
        return this.matches.filter((m) => STATUS_OPEN.includes(m.status))
      }
      if (this.statusFilter === 'active') {
        return this.matches.filter((m) => STATUS_ACTIVE.includes(m.status))
      }
      if (this.statusFilter === 'closed') {
        return this.matches.filter((m) => STATUS_FINISHED.includes(m.status))
      }
      if (this.statusFilter === 'all') {
        return this.matches
      }
      return []
    },
  },
  created () {
    axios
      .get(`/v2/agencies/${this.$store.state.auth.selectedAgency.id}/matches`)
      .then(({ data }) => {
        this.matches = data.map((match) => {
          match.status = statusByValue(MATCH_STATUSES, match.status)
          return match
        })

        data = data.sort((a, b) => (a.status.order > b.status.order ? 1 : -1))

        data = data.map(match => {
          match.status = match.status.value
          return match
        })

        this.matches = data
      })
  },
  methods: {
    setFilter (newFilter) {
      this.statusFilter = newFilter
    },
  },
}
</script>

<style lang="scss" scoped>
.content-matches {
  max-width: 74vw;
  .filters-row {
    justify-content: space-between;
    .filters {
      color: #475270;
      font-weight: 500;
      font-size: 14px;
    }
    .active {
      border-bottom: 5px solid #ee8b7b;
    }
  }

  .filter-bar {
    top: 15px;
    .project-filter {
      max-width: 288px;
      font-weight: 400;
      font-size: 16px;
      color: #b3b6b8;
    }
  }
}
</style>
