<template>
  <b-container class="noProjects-content mb-5 p-0">
    <b-card class="illustration-card text-center mt-3">
      <b-row class="mt-5">
        <b-col class="p-0">
          <b-img
            src="/images/noProjectsIllustration.svg"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <label class="h8 title ml-3 mt-5">
            Nothing to see yet!
          </label>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="h9 pl-6 pr-6 mt-2 mb-5">
          <p class="desc-text">
            No projects have matched your preferences. Stay tuned, you will be notified by email.
          </p>
        </b-col>
      </b-row>
    </b-card>
  </b-container>
</template>

<script>
export default {
  name: 'NoMatchesNew',
}
</script>

<style lang="scss" scoped>
.noProjects-content {
  max-width: 74vw;
  .illustration-card {
    background-color: #fff;
    box-shadow: 0px 4px 12px rgba(216, 216, 216, 0.5);
    border-radius: 12px;
    border: none;
    .title {
      font-size: 18px;
    }
    .desc-text {
      max-width: 350px;
      margin: auto;
    }
  }
}
</style>
