<template>
  <b-row class="mt-3 content">
    <b-col>
      <b-card class="match-card">
        <b-row class="align-items-end">
          <div
            class="ml-3"
          >
            <b-img
              :src="match.project.business.logo"
              alt="Profile pic"
              class="business-logo"
            />
          </div>
          <b-col
            class="p-0 ml-2"
          >
            <b-col class="p-0 h7 match-name">
              <router-link
                v-if="match.recruiter_id"
                :to="'/recruiter/match/' + match.id"
                class="text-decoration-none text-reset"
              >
                {{ match.project.title }}
              </router-link>
              <router-link
                v-else
                :to="'/agency/match/' + match.id"
                class="text-decoration-none text-reset"
              >
                {{ match.project.title }}
              </router-link>
              <span>
                <b-badge
                  :class="{
                    'status-starting' : startingStatuses.includes(match.status),
                    'status-active' : activeStatuses.includes(match.status),
                    'status-closed' : closedStatuses.includes(match.status)
                  }"
                >
                  {{ match.status }}
                </b-badge>
              </span>
            </b-col>
            <b-col class="p-0 general-info h7 mt-2">
              <span>
                <i class="ri-hotel-line ri-lg pr-2" />
                <label class="label-text">
                  {{ match.project.business.name }}
                </label>
              </span>
              <span v-if="match.project.office">
                <i class="ri-map-pin-line ri-lg ml-3 pr-2" />
                <label class="label-text">
                  {{ match.project.office }}
                </label>
              </span>
              <span v-if="Array.isArray(match.project.business.industries) && match.project.business.industries.length > 0">
                <i class="ri-building-3-line ri-lg ml-3 pr-2" />
                <label class="label-text">
                  {{ match.project.business.industries.map(b => b.label).join(', ') }}
                </label>
              </span>
            </b-col>
            <b-col class="p-0 created mt-1">
              <span>
                {{ "Created " + formatDate(match.created_at) }}
              </span>
            </b-col>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { formatDate } from '@/helpers/date'
import { MATCH_STATUSES } from '@/helpers/statuses'

export default {
  name: 'MatchCard',
  props: {
    match: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      startingStatuses: [
        MATCH_STATUSES.new.value,
        MATCH_STATUSES.recruiterIsInterested.value,
        MATCH_STATUSES.clientIsInterested.value,
        MATCH_STATUSES.introMeeting.value,
        MATCH_STATUSES.proposal.value,
        MATCH_STATUSES.contractSigning.value,
      ],
      activeStatuses: [
        MATCH_STATUSES.active.value,
        MATCH_STATUSES.feedback.value,
        MATCH_STATUSES.contractCompleted.value,
      ],
      closedStatuses: [
        MATCH_STATUSES.contractCanceled.value,
        MATCH_STATUSES.paused.value,
        MATCH_STATUSES.recruiterDeclined.value,
        MATCH_STATUSES.clientDeclined.value,
        MATCH_STATUSES.notHired.value,
      ],
    }
  },
  methods: {
    formatDate,
  },
}
</script>

<style lang="scss" scoped>
  .content {
      max-width: 74vw;
    }
    .match-card {
      background-color: #FFF;
      box-shadow: 0px 4px 12px rgba(216, 216, 216, 0.5);
      border-radius: 12px;
      border: none;
      .business-logo {
        width: 80px;
        height: 80px;
        object-fit: contain;
        border-radius: 8px;
        border: 2px solid #605D9F;
      }
      .match-name {
        font-size: 18px;
      }
      .general-info {
        font-weight: 500px;
        font-size: 12px;
        line-height: 14.4px;
      .label-text {
        vertical-align: text-top;
        font-weight: 500;
      }
      }
      .badge.status-badge {
        background-color: #E0FFE7;
        color: #00A725;
      }
      .created {
        font-weight: 400;
        font-size: 12px;
        color: #636077;
      }
      @media (max-width: 1116px) {
        .match-name {
          font-size: 15px;
        }
      }
  }
</style>
